import clsx from 'clsx';
import React, { useState } from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../_mApp/auth';
import { alertSuccess } from '../../../helpers/alert';
import { beautify, elipsify, plural, validateCondition } from '../../../helpers/str';
import { transform } from '../../../helpers/transform';
import { buildUrl, getInitial, getValue, parseUrl } from '../../../helpers/utils';
import BorrowerPwa from '../../../modules/Crm/Leads/BorrowerDetails/BorrowerPwa';
import DocumentDownload from '../../../modules/Storage/DocumentDownload';
import DocumentPreview from '../../../modules/Storage/DocumentPreview';
import Svg from '../../atoms/Svg';
import ActionButton from '../../molecules/ActionButton';
import DeleteButton from '../../molecules/DeleteButton';
import PopoverList from '../../molecules/PopoverList';
import DataViewer from '../DataViewer';
import DetailViewOnClick from '../DetailViewOnClick';
import Drawer from '../Drawer';
import FormDrawer from '../FormView/FormDrawer';

const TableListItem = (props) => {
    const { currentUser } = useAuth();
    const { config, form, data, canEdit, canDelete, parent_url, deleted, reload } = props;
    const icon = getValue(data, config.icon);
    const initial = getInitial(getValue(data, config.initial));
    const [details] = useState(data);

    let value = '';
    if (config.valueSum) {
        value = 0;
        config.value.forEach((val) => {
            value += getValue(data, val);
        });
    }
    if (config.type === 'statuses') {
        let tmpStatus = '';
        config.value.forEach((val) => {
            if (!tmpStatus) {
                tmpStatus = getValue(data, val);
            }
        });
        value = tmpStatus;
    } else {
        value = getValue(data, config.value);
    }

    let subvalue = getValue(data, config.subvalue);

    if (config.prefix) {
        value = value ? beautify(config.value) + ': ' + value : '';
        subvalue = subvalue ? beautify(config.subvalue) + ': ' + subvalue : '';
    }

    if (config.when) {
        let [k, v] = config.when.split(':');
        if (v.split(',').indexOf(getValue(data, k)) === -1) {
            value = '';
        }
    }

    if (config.elipsify) {
        value = elipsify(value);
    }

    const handleDeleted = () => {
        deleted();
    };

    const handleSubmitted = (response, item) => {
        if (item?.action?.alert) {
            alertSuccess(item?.action?.alert, 'Success!');
        }
        if (reload) {
            reload();
        }
    };

    const actionButtons = (items, dropdown) => {
        return items?.map((item, index) => {
            return (
                <div
                    className={clsx(dropdown ? 'dropdown-item' : 'd-flex')}
                    key={`actions_${index}`}>
                    {item.action && item.type === 'link' && (
                        <a
                            rel='noreferrer'
                            target={item?.action?.target}
                            href={parseUrl(item?.action?.url, details)}
                            className={clsx(
                                'btn btn-sm btn-icon btn-circle me-2',
                                item?.action?.className
                            )}>
                            <i class={clsx(item?.action?.icon)}></i>
                        </a>
                    )}
                    {item.action &&
                        (!item.condition || validateCondition(item.condition, details)) && (
                            <ActionButton
                                payload={item.action.payload}
                                config={{
                                    ...item,
                                    label: dropdown ? item.tooltip : '',
                                    type: dropdown ? 'text' : '',
                                }}
                                details={details}
                                submitted={(response) => handleSubmitted(response, item)}
                            />
                        )}
                    {!item.action &&
                        item.form &&
                        (!item.condition || validateCondition(item.condition, details)) && (
                            <FormDrawer
                                tooltip={item.tooltip || ''}
                                className={item.form.className || 'primary'}
                                icon={dropdown ? '' : item.icon}
                                label={dropdown ? item.label || item.tooltip : ''}
                                name={`actions_item_${index}`}
                                config={{
                                    ...item.form,
                                    ...{
                                        url:
                                            item.form.update_when &&
                                            getValue(details, item.form.update_when)
                                                ? buildUrl(
                                                      item.form.update_url,
                                                      item.form.dataKey &&
                                                          getValue(details, item.form.update_when)
                                                          ? getValue(details, item.form.dataKey)
                                                          : details,
                                                      { ignoreExtra: true }
                                                  )
                                                : buildUrl(item.form.url, details, {
                                                      ignoreExtra: true,
                                                  }),
                                        method:
                                            item.form.method ||
                                            (getValue(details, item.form.update_when)
                                                ? 'PATCH'
                                                : 'POST'),
                                        btn: item.btn || '',
                                    },
                                }}
                                data={
                                    item.form.dataKey && getValue(details, item.form.update_when)
                                        ? getValue(details, item.form.dataKey)
                                        : details
                                }
                                submitted={handleSubmitted}
                            />
                        )}
                </div>
            );
        });
    };

    return (
        <td>
            <div className='d-flex align-items-center'>
                {config.icon && (
                    <div className='symbol symbol-35px me-2'>
                        <OverlayTrigger overlay={<Tooltip>{icon}</Tooltip>}>
                            {icon && (
                                <span
                                    className={`symbol-label bg-light-${transform('class', icon)}`}>
                                    <Svg
                                        icon={icon}
                                        className={`svg-icon-2x svg-icon-${transform(
                                            'class',
                                            icon
                                        )}`}
                                    />
                                </span>
                            )}
                        </OverlayTrigger>
                    </div>
                )}
                {config.initial && (
                    <div className='symbol symbol-circle symbol-35px me-2'>
                        {config.path && (
                            <a
                                rel='noreferrer'
                                target={config.target}
                                href={parseUrl(config.path, details, config.queryParams)}>
                                <span className='symbol-label fs-3 bg-light-primary text-primary'>
                                    {initial.toUpperCase()}
                                </span>
                            </a>
                        )}
                        {!config.path && (
                            <span className='symbol-label fs-3 bg-light-primary text-primary'>
                                {initial.toUpperCase()}
                            </span>
                        )}
                    </div>
                )}
                <div className='d-flex flex-column align-items-start'>
                    {config.path && !config.type && config.value && (
                        <a
                            rel='noreferrer'
                            target={config.target}
                            href={parseUrl(config.path, details, config.queryParams)}
                            className={`fw-bold text-hover-primary fs-7 ${
                                config.target ? 'text-dark' : 'text-primary'
                            }`}>
                            {value}
                        </a>
                    )}
                    {!config.path && !config.type && config.value && (
                        <span className={`${config.color ? 'fw-bold text-' + config.color : ''}`}>
                            {config.transform ? transform(config.transform, value) : value}
                        </span>
                    )}
                    {config.type === 'quick_view' && (
                        <Drawer
                            type='text'
                            title={getValue(data, config.title)}>
                            <DataViewer config={getValue(data, config.value)} />
                        </Drawer>
                    )}
                    {config.type === 'detail_view' && (
                        <DetailViewOnClick
                            config={details}
                            title={config.title}
                            items={config.items}
                        />
                    )}

                    {config.type === 'arrays' && value && (
                        <ul className='fs-8 p-0'>
                            {config.arrays.map((array, arrayIndex) => {
                                return (
                                    <React.Fragment key={arrayIndex}>
                                        {getValue(data, array, true, [])?.map((row, listIndex) => {
                                            return (
                                                <li key={listIndex}>
                                                    <Drawer
                                                        type='text'
                                                        title={
                                                            <>
                                                                {config.transform
                                                                    ? transform(
                                                                          config.transform,
                                                                          getValue(
                                                                              row,
                                                                              config.field
                                                                          )
                                                                      )
                                                                    : getValue(row, config.field)}
                                                            </>
                                                        }>
                                                        <DataViewer config={row} />
                                                    </Drawer>
                                                </li>
                                            );
                                        })}
                                    </React.Fragment>
                                );
                            })}
                        </ul>
                    )}
                    {config.type === 'list' && value && (
                        <ul className='fs-7'>
                            {getValue(data, config.array, true, [])?.map((row, listIndex) => {
                                return (
                                    <li key={listIndex}>
                                        {config.transform
                                            ? transform(
                                                  config.transform,
                                                  getValue(row, config.field)
                                              )
                                            : getValue(row, config.field)}
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                    {config.type === 'popover' && (
                        <OverlayTrigger
                            trigger='click'
                            rootClose
                            overlay={
                                <Popover id='popover-basic'>
                                    <Popover.Body className='p-0 m-0'>
                                        <table className='table table-striped gx-5'>
                                            <tbody>
                                                {config?.popover?.map((popover) => {
                                                    return (
                                                        <tr key={popover}>
                                                            <td>{beautify(popover)}</td>
                                                            <td>
                                                                {config.transform
                                                                    ? transform(
                                                                          config.transform,
                                                                          getValue(data, popover)
                                                                      )
                                                                    : value}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </Popover.Body>
                                </Popover>
                            }>
                            <span
                                className={`${config.color ? 'fw-bold text-' + config.color : ''}`}
                                role='button'>
                                {config.transform ? transform(config.transform, value) : value}
                            </span>
                        </OverlayTrigger>
                    )}
                    {config.type === 'popover-with-value' && (
                        <OverlayTrigger
                            trigger='click'
                            rootClose
                            overlay={
                                <Popover id='popover-basic'>
                                    <Popover.Body className='p-0 m-2'>
                                        <table className='table table-striped gx-5'>
                                            <tbody>
                                                {config?.popover?.map((popover) => {
                                                    return (
                                                        <tr key={popover}>
                                                            <td>{beautify(popover.value)}</td>
                                                            <td>
                                                                {popover.transform
                                                                    ? transform(
                                                                          popover.transform,
                                                                          getValue(
                                                                              data,
                                                                              popover.value
                                                                          )
                                                                      )
                                                                    : getValue(data, popover.value)}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </Popover.Body>
                                </Popover>
                            }>
                            <span
                                className={`${config.color ? 'fw-bold text-' + config.color : ''}`}
                                role='button'>
                                {isNaN(getValue(data, config.value))
                                    ? getValue(data, config.value)
                                    : transform('amount', getValue(data, config.value))}
                            </span>
                        </OverlayTrigger>
                    )}
                    {config.type === 'mapping' && <span>{config.mapping[value]}</span>}
                    {config.type === 'resource' && (
                        <a
                            target='_blank'
                            rel='noreferrer'
                            href={`${config.parent}/${plural(data[config.source])}/${
                                data[data[config.source]]
                            }`}>
                            {data[data[config.source]]}
                        </a>
                    )}
                    {config.type === 'email' && (
                        <span className=''>
                            <i className='text-info bi bi-envelope me-2'></i>
                            <a
                                className='text-info'
                                href={`mailto:${value}`}>
                                {value}
                            </a>
                        </span>
                    )}
                    {config.type === 'badge' && !config.tooltip && (
                        <div
                            className={`badge badge-${
                                config.badgeType ? `${config.badgeType}-` : ''
                            }${config.color || transform('class', value) || 'dark'} fw-bolder`}>
                            {config.transform ? transform(config.transform, value) : value}
                        </div>
                    )}
                    {config.type === 'linked_url' && value && (
                        <>
                            <OverlayTrigger overlay={<Tooltip>View Linked Drawdown</Tooltip>}>
                                <Link
                                    to={parseUrl(config.path, data)}
                                    target='_blank'>
                                    <Svg
                                        icon='lead_programs_maps'
                                        className='svg-icon-2 text-danger'
                                    />
                                </Link>
                            </OverlayTrigger>
                        </>
                    )}
                    {config.type === 'statuses' && (
                        <div className='d-flex align-items-center'>
                            {config.tooltip && (
                                <OverlayTrigger
                                    overlay={<Tooltip>{getValue(data, config.tooltip)}</Tooltip>}>
                                    <div
                                        role='button'
                                        className={`badge py-3 px-4 badge-light-${
                                            config.color || transform('class', value) || 'dark'
                                        } fw-bolder`}>
                                        {config.transform
                                            ? transform(config.transform, value)
                                            : value}
                                    </div>
                                </OverlayTrigger>
                            )}
                            {!config.tooltip && (
                                <span
                                    className={`badge py-3 px-4 badge-light-${
                                        config.color || transform('class', value) || 'dark'
                                    } fw-bolder`}>
                                    {config.transform ? transform(config.transform, value) : value}
                                </span>
                            )}
                            {config.links?.[value]?.path && (
                                <Link
                                    target='_blank'
                                    to={parseUrl(
                                        config.links?.[value]?.path,
                                        details,
                                        config.links?.[value]?.queryParams || {}
                                    )}>
                                    <i
                                        className={`fs-3 bi bi-arrow-right-circle-fill ms-1 text-${
                                            config.color || transform('class', value) || 'dark'
                                        }`}></i>
                                </Link>
                            )}
                        </div>
                    )}
                    {config.type === 'leadStatus' && (
                        <>
                            {(getValue(data, config.value[0]) !== 'active' || !config.value[1]) && (
                                <div
                                    className={`badge py-3 px-4 badge-light-${
                                        config.color ||
                                        transform('class', getValue(data, config.value[0])) ||
                                        'dark'
                                    } fw-bolder`}>
                                    {config.transform
                                        ? transform(
                                              config.transform,
                                              getValue(data, config.value[0])
                                          )
                                        : getValue(data, config.value[0])}
                                </div>
                            )}
                            {getValue(data, config.value[0]) === 'active' && config.value[1] && (
                                <div
                                    className={`badge py-3 px-4 badge-light-${
                                        config.color ||
                                        transform('class', getValue(data, config.value[1])) ||
                                        'dark'
                                    } fw-bolder`}>
                                    {config.transform
                                        ? transform(
                                              config.transform,
                                              getValue(data, config.value[1])
                                          )
                                        : getValue(data, config.value[1])}
                                </div>
                            )}
                        </>
                    )}
                    {config.type === 'popover_list' && (
                        <PopoverList
                            config={config}
                            data={data}
                        />
                    )}
                    {config.type === 'badge' && config.tooltip && (
                        <OverlayTrigger
                            overlay={<Tooltip>{getValue(data, config.tooltip)}</Tooltip>}>
                            <div
                                role='button'
                                className={`badge badge-${
                                    config.color || transform('class', value) || 'dark'
                                } fw-bolder`}>
                                {config.transform ? transform(config.transform, value) : value}
                            </div>
                        </OverlayTrigger>
                    )}
                    {config.type === 'is_badges' &&
                        config.value.map((badge) => {
                            return (
                                <React.Fragment key={badge}>
                                    {getValue(data, badge) && (
                                        <div
                                            className={`badge m-1 badge-${
                                                config.color || transform('class', badge) || 'dark'
                                            } fw-bolder`}>
                                            {beautify(badge.replaceAll('is_', ''))}
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    {config.type === 'badges' &&
                        value.map((badge) => {
                            return (
                                <div
                                    key={badge}
                                    className={`badge m-1 badge-${
                                        config.color || transform('class', badge) || 'dark'
                                    } fw-bolder`}>
                                    {config.transform ? transform(config.transform, badge) : value}
                                </div>
                            );
                        })}
                    {config.type === 'chips' && (
                        <div className='d-flex flex-row flex-wrap'>
                            {value.map((row, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`badge m-1 badge-light fw-bolder`}>
                                        {config.transform
                                            ? transform(config.transform, row[config.key])
                                            : row[config.key]}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {config.type === 'btn_badge' && (
                        <div
                            className={`btn btn-sm btn-${
                                config.color || transform('class', value) || 'dark'
                            } fw-bolder`}>
                            {config.transform ? transform(config.transform, value) : value}
                        </div>
                    )}
                    {config.type === 'progress' && (
                        <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2'>
                                <span className='text-muted me-2 fs-7 fw-semibold'>
                                    20% Complete
                                </span>
                            </div>
                            <div className='progress h-6px w-100'>
                                <div
                                    className='progress-bar bg-warning'
                                    role='progressbar'
                                    style={{ width: '20%' }}></div>
                            </div>
                        </div>
                    )}
                    {config.type === 'actions' && (
                        <div className='d-flex'>
                            {config.pwa && !currentUser?.company_id && (
                                <BorrowerPwa
                                    details={details}
                                    config={config.pwa}
                                />
                            )}
                            {/* <Drawer
                                icon=''
                                title='Details'>
                                <DataViewer config={details} />
                            </Drawer> */}
                            {config.mode === 'dropdown' ? (
                                <div
                                    className='btn btn-icon btn-primary btn-circle'
                                    data-bs-toggle='dropdown'>
                                    <i className='fs-3 bi bi-three-dots'></i>
                                    <div className='dropdown-menu'>
                                        {actionButtons(config.items, true)}
                                    </div>
                                </div>
                            ) : (
                                <>{actionButtons(config.items)}</>
                            )}

                            {(details.is_editable === undefined || details.is_editable) && canEdit && (
                                <FormDrawer
                                    icon='pencil-fill'
                                    name='form'
                                    config={{
                                        ...form,
                                        url: buildUrl(form.url + details?.id),
                                        method: 'PATCH',
                                    }}
                                    data={details}
                                    submitted={handleSubmitted}
                                />
                            )}
                            {(details.is_editable === undefined || details.is_editable) &&
                                canDelete &&
                                currentUser?.user_type !== 'borrower' &&
                                parent_url && (
                                    <DeleteButton
                                        deleted={handleDeleted}
                                        url={`${parent_url}${getValue(
                                            details,
                                            config.id || 'id'
                                        )}/`}
                                    />
                                )}
                        </div>
                    )}
                    {config.type === 'preview' && getValue(details, config.previewId) && (
                        <DocumentPreview
                            config={{
                                label: 'View File',
                                position: 'start',
                                btn: config.btnIcon || 'la la-file',
                                ...config,
                            }}
                            documents={[
                                {
                                    storage_id: getValue(details, config.previewId),
                                    mime_type: config.mime || 'application/pdf',
                                },
                            ]}
                        />
                    )}
                    {config.type === 'download' && getValue(details, config.downloadId) && (
                        <DocumentDownload
                            config={{
                                label: 'View File',
                                position: 'start',
                                btn: 'bi bi-download',
                                ...config,
                            }}
                            documents={[
                                {
                                    storage_id: getValue(details, config.downloadId),
                                    mime_type: config.mime || 'application/pdf',
                                },
                            ]}
                        />
                    )}
                    {config.subvalue && typeof config.subvalue !== 'object' && (
                        <span className='text-muted d-block fs-7'>
                            {config.subpath && config.subvalue && (
                                <a
                                    rel='noreferrer'
                                    target={config.target}
                                    href={parseUrl(config.subpath, details)}
                                    className={`fw-bold text-hover-primary ${
                                        config.target ? 'text-dark' : 'text-primary'
                                    }`}>
                                    {config.subTransform
                                        ? transform(config.subTransform, subvalue)
                                        : subvalue}
                                </a>
                            )}
                            {!config.subpath && config.subvalue && (
                                <span
                                    className={`${
                                        config.color ? 'fw-bold text-' + config.color : ''
                                    }`}>
                                    {config.subTransform
                                        ? transform(config.subTransform, subvalue)
                                        : subvalue}
                                </span>
                            )}
                        </span>
                    )}
                    {config.subvalue && typeof config.subvalue === 'object' && (
                        <span className='text-muted d-block fs-7'>
                            {config.subvalue.map((k) => {
                                const v = getValue(data, k);
                                return (
                                    <span key={k}>
                                        {v ? (
                                            <span className='me-2'>
                                                {beautify(k)}: &nbsp;
                                                {config.subTransform
                                                    ? transform(config.subTransform, v)
                                                    : v}
                                            </span>
                                        ) : (
                                            <></>
                                        )}
                                    </span>
                                );
                            })}
                        </span>
                    )}
                </div>
            </div>
        </td>
    );
};

export default TableListItem;
